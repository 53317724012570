import {twMerge} from '@/stylesheets/twMerge';
export {default as Col} from '@/components/base/layouts/utils/Grid/Col';

export interface GridProps
  extends Pick<React.HTMLProps<HTMLDivElement>, 'style' | 'role'> {
  children: React.ReactNode;
  className?: string;
  componentName?: string;
}

export default function Grid({
  children,
  componentName,
  className,
  ...props
}: GridProps) {
  return (
    <div
      className={twMerge(
        'container grid grid-cols-4 sm:grid-cols-8 md:grid-cols-12 gap-x-gutter gap-y-2xl',
        className,
      )}
      data-component-name={componentName}
      {...props}
    >
      {children}
    </div>
  );
}
