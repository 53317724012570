import {twMerge} from '@/stylesheets/twMerge';

export interface ColConfig {
  xs?: number;
  sm?: number;
  md?: number;
  lg?: number;
  xl?: number;
}

export interface ColProps {
  children?: React.ReactNode;
  className?: string;
  span?: number | ColConfig;
  start?: number | ColConfig;
  center?: boolean;
}

export default function Col({
  children = null,
  className,
  span = {xs: 4, sm: 8, md: 12},
  start = 1,
  center = false,
  ...props
}: ColProps) {
  type TotalColumnsType = {
    xs: number;
    sm: number;
    md: number;
    lg: number;
    xl: number;
  };

  const totalColumns: TotalColumnsType = {xs: 4, sm: 8, md: 12, lg: 12, xl: 12};

  const getClassByNameProp = (name: string, prop: number | ColConfig) =>
    typeof prop === 'object'
      ? Object.entries(prop)
          .map(([key, value]) => `${key}:col-${name}-${value}`)
          .join(' ')
      : `col-${name}-${prop}`;

  const calculateStartClass = (spanConfig: ColConfig | number): string => {
    return Object.entries(spanConfig)
      .map(([key, value]) =>
        value !== undefined
          ? `${key}:col-start-${
              Math.floor(
                (totalColumns[key as keyof typeof totalColumns] - value) / 2,
              ) + 1
            }`
          : '',
      )
      .join(' ');
  };

  const startClass = center
    ? calculateStartClass(span)
    : getClassByNameProp('start', start);

  const spanClass = getClassByNameProp('span', span);

  return (
    <div
      className={twMerge('col-span-4', [spanClass, startClass], className)}
      {...props}
    >
      {children}
    </div>
  );
}
